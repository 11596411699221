import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";

import { Button } from "react-bootstrap";
import { IconLogin } from "@tabler/icons-react";

/* </Dropdown> className="nav-link d-flex lh-1 text-reset p-0 show" */
function LoginWidget() {
  const navigate = useNavigate();

  const { route, user, signOut } = useAuthenticator((context) => [
    context.route,
    context.user,
    context.signOut,
  ]);
  if (route !== "authenticated") {
    return (
      <Button variant="outline-light" onClick={() => navigate("/dashboard")}>
        <IconLogin className="icon" />
        Login
      </Button>
    );
  }

  const {
    attributes: { email, name },
  } = user;
  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle as={NavLink}>
        <span
          className="avatar avatar-sm"
          style={{ backgroundImage: `url(./static/avatars/000m.jpg)` }}
        ></span>
        <div className="d-none d-xl-block ps-2">
          <div>{name}</div>
          <div className="mt-1 small text-muted">{email}</div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#action/3.1">Profile</Dropdown.Item>
        <Dropdown.Item href="#action/3.2">Settings</Dropdown.Item>
        <Dropdown.Item onClick={() => signOut()}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LoginWidget;
