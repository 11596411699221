import { Outlet } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { IconTicket } from "@tabler/icons-react";

import LoginWidget from "./UI/loginWidget";

function Page() {
  return (
    <>
      <NavBar />

      <Outlet />
    </>
  );
}
function NavBar() {
  const index = 5 || Math.floor(Math.random() * 6);

  const brand = [
    "Supa-Sub",
    "Chit-Ching",
    "Fare Play",
    "PubSubPay",
    "Mega Pass",
    "Chit Net",
  ].find((name, i) => i === index);

  return (
    <Navbar as="header" expand="lg" className="bg-green text-white">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="/">
          <IconTicket />
          {brand}
        </Navbar.Brand>
        <Nav className="me-auto order-last">
          <Navbar.Text className="text-muted ">
            A simple subscription service
          </Navbar.Text>
          <LoginWidget />
        </Nav>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/dashboard">Subscribers</Nav.Link>
            <Nav.Link href="/dashboard">Producers</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Page;
