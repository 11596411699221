import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import "@tabler/core/dist/css/tabler.min.css";

import Landing from "./Landing";
import { RequireAuth } from "./UI/requireAuth";
import { Login } from "./UI/login";
import SetCookie from "./setCookie";
import { Home } from "./home";
import { UserDashboard } from "./UserDashboard";

const routes = [
  {
    path: "/",
    element: <Landing />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "dashboard/*",
        element: (
          <RequireAuth>
            <UserDashboard />
          </RequireAuth>
        ),
      },
      {
        path: "/login",
        element: (
          <RequireAuth>
            <SetCookie />
          </RequireAuth>
        ),
      },
      { path: "/auth", element: <Login /> },
    ],
  },
];

const router = createBrowserRouter(routes);

function App() {
  console.log(window.location.href);
  const params = new URLSearchParams(window.location.search);
  const popupLogin = params.has("popupLogin");
  console.log({ popupLogin });

  return (
    <Authenticator.Provider>
      <RouterProvider router={router} />
    </Authenticator.Provider>
  );
}

export default App;
