import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";

export function UserDashboard() {
  return (
    <>
      <Container className="page-header">
        <Row>
          <Col>
            <div className="page-pretitle">Subscriber</div>
            <h2 className="page-title">Dashboard</h2>
          </Col>
        </Row>
      </Container>

      <Row className="g-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title as="h2">Recent Support</Card.Title>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Total Minutes</th>
                    <th>Last Visit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>120</td>
                    <td>2021-09-01</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>120</td>
                    <td>2021-09-01</td>
                  </tr>
                  <tr>
                    <td>Medium Inc</td>
                    <td>1m</td>
                    <td>2023-09-01</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title as="h2">Recent Support</Card.Title>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Total Minutes</th>
                    <th>Last Visit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>120</td>
                    <td>2021-09-01</td>
                  </tr>
                  <tr>
                    <td>John Doe</td>
                    <td>120</td>
                    <td>2021-09-01</td>
                  </tr>
                  <tr>
                    <td>Medium Inc</td>
                    <td>1m</td>
                    <td>2023-09-01</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
