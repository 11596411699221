import { API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect } from "react";

import { Button } from "react-bootstrap";
import { IconLogin } from "@tabler/icons-react";

function loginAPI() {
  const apiName = "chit";
  const path = "/login";
  const myInit = {
    withCredentials: true, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  API.get(apiName, path, myInit)
    .then((response) => {
      // Add your code here
    })
    .catch((error) => {
      console.log(error.response);
    });
}

function SetCookie() {
  const { route } = useAuthenticator((context) => [context.route]);

  useEffect(() => {
    if (route === "authenticated") {
      loginAPI();
    }
  }, [route]);

  if (route !== "authenticated") {
    return (
      <Button variant="info">
        <IconLogin className="icon" />
        Login to set cookie
      </Button>
    );
  }
  return (
    <div>
      <h1>Set Cookie</h1>
      <button onClick={() => window.close()}>Close</button>
    </div>
  );
}

export default SetCookie;
