import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import { IconCoins, IconVip, IconAdOff, IconGrowth } from "@tabler/icons-react";
import { Col, Container, Image, Row } from "react-bootstrap";

export function Home() {
  return (
    <>
      <section>
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="text-center order-2 order-md-1 text-md-left">
              <h1 class="display-2 font-weight-bold mb-4">
                ChitNET
                <small class="text-body-secondary"> Give a Chit</small>
              </h1>
              <p class="lead fs-3">
                An <em>Access All Areas</em> pass for the web, Premium News,
                Blogs, Games, Video, and more...
              </p>
              <Button variant="success">Sign me up!</Button>
            </Col>
            <Col md={6} className="text-center order-1 order-md-2">
              <Image src="/images/vip_pass.png" fluid />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="mb-5">
          <Row xs={1} md={3}>
            <Col>
              <Card className="h-100">
                <span className="pt-5 text-center text-green">
                  <IconVip className="tabler-icon" size={64} />
                </span>
                <Card.Body>
                  <Card.Title as="DivStyledAsH3">Premium Content</Card.Title>
                  <Card.Text>
                    Say goodbye to paywalls, get access to premium content
                    directly from the source. No more hacks or workarounds, just
                    a single simple subscription
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="h-100">
                <span className="pt-5 text-center text-green">
                  <IconAdOff className="tabler-icon" size={64} />
                </span>
                <Card.Body>
                  <Card.Title as="DivStyledAsH3">No Ads</Card.Title>
                  <Card.Text>
                    Get access to content without ads, no tracking, no
                    profiling, Content producers get paid directly by you. Make
                    Ad Blockers a thing of the past and support the creators,
                    journalists, artists, and developers who work hard to
                    surpise and entertain us all.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="h-100">
                <span className="pt-5 text-center text-green">
                  <IconGrowth className="tabler-icon" size={64} />
                </span>
                <Card.Body>
                  <Card.Title as="DivStyledAsH3">
                    Sustainable and Supportive
                  </Card.Title>
                  <Card.Text>
                    Pay your way and support the people who make the content you
                    love.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mt-6 pt-6 bg-white">
        <Container fluid>
          <Row className="text-center">
            <Col lg={12}>
              <div class="display-5 mb-4">
                Everyone deserves to get paid
                <small class="text-body-secondary">
                  - ChitNet makes that happen
                </small>
              </div>
              <div className="m-4 p-4">
                <p class="lead fs-2">
                  All you have to do is use the internet as you normally would.
                  visit sites you enjoy and read the content you want. ChitNet
                  will take care of the rest.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <Image src="/images/network.png" fluid />
            </Col>
            <Col lg={6}>
              <div class="display-5 mb-4">What is ChitNet?</div>
              <p class="lead fs-2">
                A membership network for premium content across the internet. A
                single account that provides access to paid content on 1000’s of
                websites. Without the need to sign up or pay for each one.
              </p>{" "}
              <p class="lead fs-2">
                Replaces ad supported content that allows the creators greater
                freedom and removes bias from articles. No tracking by default
                each site gets unique visitor ids that prevent correlation
                between sites. You can choose to share as much or as little
                information about yourself as you want.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={6}>
              <div class="display-5 mb-4">Buy everyone a coffee!</div>
              <p class="lead fs-2">
                Support all the content producers you enjoy, read and watch. Let
                us distribute your monthly outlay based on how much you use.
                Watch all the things, with ChitNet you no longer need to choose
                who you want to support today, this month or year.
              </p>
            </Col>
            <Col lg={6}>
              <Image src="/images/coffee.png" fluid thumbnail />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col md={4} sm={12}>
              <Card>
                <div className="card-stamp card-stamp-lg">
                  <div className="card-stamp-icon bg-success">
                    <IconCoins className="icon" />
                  </div>
                </div>
                <Card.Body>
                  <Card.Text>
                    A simple subscription service for everything, News, Blogs,
                    Games, Video, and more...
                  </Card.Text>
                  <Button variant="success">Sign me up!</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card>
                <div className="card-stamp card-stamp-lg">
                  <div className="card-stamp-icon bg-success">
                    <IconCoins className="icon" />
                  </div>
                </div>
                <Card.Body>
                  <Card.Text>
                    A simple subscription service for everything, News, Blogs,
                    Games, Video, and more...
                  </Card.Text>
                  <Button variant="success">Sign me up!</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card>
                <div className="card-stamp card-stamp-lg">
                  <div className="card-stamp-icon bg-success">
                    <IconCoins className="icon" />
                  </div>
                </div>
                <Card.Body>
                  <Card.Text>
                    A simple subscription service for everything, News, Blogs,
                    Games, Video, and more...
                  </Card.Text>
                  <Button variant="success">Sign me up!</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <blockquote class="blockquote">
                <p>
                  a "chit" refers to a small piece of paper or a note that
                  acknowledges a debt or an obligation to pay. It can be a
                  written promise to pay a certain amount of money or provide a
                  specific service at a later date.
                </p>
              </blockquote>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
